<template>
  <section class="loginBox">
    <v-form ref="registrationForm">
      <v-select
        v-model="role"
        :items="roles"
        item-text="name"
        item-value="value"
        outlined
        placeholder="Role"
      ></v-select>
      <v-text-field
        v-model="fullname"
        outlined
        :placeholder="role == 'user' ? 'Fullname' : 'Business Name'"
        :rules="nameRule"
      ></v-text-field>
      <v-text-field
        v-model="email"
        outlined
        :placeholder="role == 'user' ? 'Email Address' : 'Business Email'"
        :rules="emailRules"
      ></v-text-field>
      <v-text-field
        v-if="role == 'business'"
        v-model="registrationNumber"
        placeholder="Registration Number"
        outlined
      ></v-text-field>
      <v-text-field
        v-if="role == 'business'"
        v-model="contactPersonName"
        placeholder="Contact Person Name"
        outlined
      ></v-text-field>
      <v-text-field
        v-if="role == 'business'"
        v-model="contactPersonPhone"
        placeholder="Contact Person Phone"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="password"
        outlined
        placeholder="Password"
        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showpassword ? 'text' : 'password'"
        @click:append="showpassword = !showpassword"
        :rules="[rules.required, rules.min]"
      ></v-text-field>

      <p>
        Include a minimum of 8 characters and at least one number and one
        letter. No spaces, please.
      </p>
      <v-text-field
        v-model="confirmPassword"
        outlined
        placeholder="Confirm Password"
        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showpassword ? 'text' : 'password'"
        @click:append="showpassword = !showpassword"
        :rules="[rules.required, rules.min]"
      ></v-text-field>
      <p>
        Your personal data will be used to support your experience throughout
        this website, to manage access to your account, and for other purposes
        described in our
        <router-link to="/privacypolicy">privacy policy</router-link>.
      </p>

      <vue-dropzone
        v-if="role == 'business'"
        class="mb-2"
        ref="myVueDropzone"
        id="dropzone"
        :options="dropzoneOptions"
      ></vue-dropzone>
      <div class="mb-5" v-if="role == 'business'">
        <small>Upload your Pan/Vat and Registration Certificate</small>
      </div>

      <v-btn large class="viewMore mb-3" @click="validate" v-if="!loading"
        >Create a new account <v-icon> mdi-chevron-right </v-icon
        ><v-icon> mdi-chevron-right </v-icon></v-btn
      >
      <v-btn large class="viewMore mb-3" loading v-if="loading"></v-btn>
    </v-form>

    <div class="mt-3">
      Already have an account?
      <router-link to="/login"> Login </router-link>
    </div>
    <div class="fixedVerify" v-if="verifyPopup">
      <OTPVerify />
    </div>
    <v-snackbar v-model="snackbar" :color="color"> {{ message }}</v-snackbar>
  </section>
</template>

<script>
import OTPVerify from "@/components/user/authorization/OTPVerify";
import { mapActions, mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "RegisterForm",
  data: () => ({
    email: "",
    fullname: "",
    password: "",
    confirmPassword: "",
    registrationNumber: undefined,
    verifyPopup: false,
    showpassword: false,
    loading: false,
    nameRule: [(v) => !!v || "Fullname is required."],
    emailRules: [(v) => !!v || "Email is required."],
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
    },
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    roles: [
      {
        name: "User",
        value: "user",
      },
      {
        name: "Business",
        value: "business",
      },
    ],
    role: "user",
    medias: [],
    snackbar: false,
    message: "",
    color: "red",
  }),
  components: {
    OTPVerify,
    vueDropzone: vue2Dropzone,
  },
  computed: {
    ...mapGetters(["allRegister", "allVerifyPopup"]),
  },
  methods: {
    ...mapActions(["postRegister"]),
    async validate() {
      this.loading = true;
      if (this.role == "business") {
        this.medias = [];
        let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
        fileBundles.forEach((ele) => {
          if (ele.type.split("/")[0] == "image") {
            this.medias.push(ele);
          }
        });
      }
      let data = {};
      data.fullname = this.fullname;
      data.password = this.password;
      data.email = this.email;
      data.role = this.role;
      data.registrationNumber = this.registrationNumber;
      data.contactPersonName = this.contactPersonName;
      data.contactPersonPhone = this.contactPersonPhone;
      data.registrationNumber = this.registrationNumber;
      if (this.$refs.registrationForm.validate()) {
        await this.postRegister(data);
        if (this.allRegister.status == 200) {
          this.loading = false;
          this.verifyPopup = true;
        } else {
          this.loading = false;
          this.snackbar = true;
          this.color = "red";
          this.message = this.allRegister.data.message;
        }
        this.verifyPopup = this.allVerifyPopup;
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100% !important;
  background: #0878d4 !important;
  min-height: 55px;
}
.socialBtn {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin: 15px 0;
  li {
    margin-right: 10px;
  }
  .fb {
    background: #3b5998 !important;
  }
  .gp {
    background: #d34836 !important;
  }
  .v-btn {
    min-width: 20px !important;
    width: 55px !important;
    min-height: 50px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
    }
  }
}
.v-btn {
  color: #fff;
}
</style>